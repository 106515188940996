import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import TopBar from "./components/TopBar";
import Home from "./views/Home";
import Footer from "./components/Footer";
import Terms from "./views/Terms";
import Ewacalculator from "./views/Ewacalculator";
import Policy from "./views/Policy";
import LendingPartners from "./views/LendingPartners";
import GrievanceRedressal from "./views/GrevianceRedressal";
import ContactUs from "./views/contactUs";
import Support from "./views/Support";

function App() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <TopBar />
      <div style={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms_of_use" element={<Terms />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/lending-partners" element={<LendingPartners />} />
          <Route path="/grievance" element={<GrievanceRedressal />} />
          <Route path="/connect" element={<ContactUs />} />
          <Route path="/support" element={<Support />} />
          <Route element={<Ewacalculator />} path="/calculator" />
        </Routes>
      </div>
      <div style={{ backgroundColor: "#216FB8" }}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
