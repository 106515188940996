import React from "react";
import "./css/Policy.css";
const Policy = () => {
  return (
    <>
      <div className="container mb-5">
        <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
        <span style={{marginTop:20}}>
          <b>Instape Synergies Private Limited</b> is a Company incorporated under the Companies Act, 2013 (hereinafter referred to as
          <b>“the Company”/“We”/“Us”)</b>. The Company has developed a platform that allows the employee to withdraw an advance against the earned amount of
          salary at any point of time at agreed interest and processing fees and such facility is extended by the bank (hereinafter referred to as
          <b>“Instape App”/“Application”)</b>. The Company commits to deliver services through the Application that ensures an impermeable privacy protection to
          the Users of the Instape App (may alternatively be referred to as <b>“You”</b>, <b>“User”</b> or <b>“Employee")</b>. This Privacy Policy shall explain
          the mechanism of how the collection, use, disclosure and protection of the information shared by You shall function upon visiting the Application.
          <br></br>
          <br></br>
          This Privacy Policy is in conjunction with Information Technology Act, 2000 read with Information Technology (Reasonable Security Practices and
          Procedures and Sensitive Personal Data or Information) Rules, 2011 in addition to more relevant guidelines of the Reserve Bank of India (hereinafter
          referred to as “RBI”) and various other applicable laws. Kindly note that the terms and conditions mentioned in the Policy can be revised at any time
          by the Company in accordance with the latest guidelines, applicable laws and various business requirements of the Company. Any such revision would not
          be communicated separately to any Users. <br></br>
          <br></br>
          This Privacy Policy is incorporated into and at all times is subject to and is to be read in conjunction with the Terms of Use of the Application.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>1. CONSENT </b>
          <br></br>
          <br></br>
          1.1. You hereby expressly consent to provide the information that may be required in relation to the Services (as defined below) being rendered on the
          Application by Us. You acknowledge that We shall collect the information detailed under this Privacy Policy to facilitate lending &amp; non-lending
          services by partnering with various financial lenders, third parties, service providers, etc. based on your requirement to avail such Services
          <b>(“Services”)</b>.<br></br>
          <br></br>
          1.2. To avail any Services being provided by the Application (through partnership with the lenders or any other third parties), it is mandatory that
          You read, understand, acknowledge and unconditionally agree to be bound by the terms and conditions of this Privacy Policy.<br></br>
          <br></br>
          1.3. In an event You do not agree to this Policy or any part thereof, You are requested not to access/use the Application or avail such Services.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>2. COLLECTION OF INFORMATION</b>
          <br></br>
          <br></br>2.1 For accessing and usage of our Services, We may collect information including but not limiting to: <br></br>
          <br></br>
          <p style={{ padding: "0 20px" }}>a. Name </p>
           <p style={{ padding: "0 20px" }}>b. Contact Number</p> 
          <p style={{ padding: "0 20px" }}>c. Date of Birth</p> 
          <p style={{ padding: "0 20px" }}>d. Gender </p>
          
          <p style={{ padding: "0 20px" }}>e. Email Id</p> 
          <p style={{ padding: "0 20px" }}>f. Address (Temporary/Permanent)</p> <p style={{padding:'0 20px'}}>g. Employment details like name of the company, employee id etc. and
          any other employee information as may be required for rendering services requested by You on the Application and as requested by the
          Bank/lenders/third parties providing the Services from time to time. </p><p style={{padding:'0 20px'}}>h. In accordance with the RBI regulations, banks/lenders/third party
          service providers may ask for additional on-boarding/Know your Customer (KYC) documents via one- time access through camera, microphone, location etc.
          The consent for the same shall be acquired explicitly through You.</p> <p style={{padding:'0 20px'}}>i. Usage Data- We may collect data as to how you interact with the
          Application such as screen actions, dates and times of access, pages viewed etc.</p> <p style={{padding:'0 20px',fontStyle:'italic'}}>*name, contact number, date of birth, gender, email id,
          address (temporary/permanent) would be categorised as “Personal Data”.</p>
           2.2 The Application will share the information securely and ensure that all personal information recipients comply with confidentiality,
          fidelity and secrecy obligations and sign covenants in this regard. We may make information available to third parties that are financial and
          non-financial companies, various service providers as requested by You or otherwise when required or permitted by law.<br></br>
          <br></br> 2.3 We may use cookies through Application as the information helps Us identify You in order to make our Website/Application more user
          friendly. You may choose to decline the use of cookies, however, if You choose to do this it might affect Service on some parts of our
          Website/Application. <br></br>
          <br></br>2.4 The details of the third party to whom the data may be shared with, is encapsulated under Schedule I- Details of Third Party. <br></br>
          <br></br>2.5 Upon the collection of such data, during the process of data collection or any other stage that You choose to revoke consent to continue
          providing the data aforementioned, You shall have the right to deny usage of Personal Data collected at a prior-stage and/or specific data, restrict
          disclosure to third parties, disallow data retention and make the Application delete/forget any data as requested by You. <br></br>
          <br></br>2.6 Additionally, the revocation/deletion/restriction/denial of usage of the required data may result in a restricted functioning or in some
          cases, non-functioning of the Application as some of the Services can only be facilitated through collection of such data. <br></br>
          <br></br>2.7 The Application shall not be collecting any mobile phone resources like file and media, contact list, call logs, telephony functions and
          other relevant data unless expressly authorised by You for the facilitation of the functioning of the Services availed by the Application. <br></br>
          <br></br>2.8 It is also hereby declared that, in no circumstances unless mandated by the Law, do We collect any bio-metric data.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>3. DATA PROTECTION</b>
          <br></br>
          <br></br>3.1 The Application shall allow access of Your data strictly to the authorised personnel only who would be involved in the transaction
          requested by You or involved in transmission of data from the Application to the Bank/Lender/other service provider.<br></br>
          <br></br>3.2 We ensure that every data or information that We collect from You is stored in servers located in India and the same is in compliance
          with all the statutory/regulatory obligations.<br></br>
          {/* <br></br>3.3 We assure data protection of the highest levels as We have obtained ___________ data protection standard certification.<br></br> */}
          <br></br>3.3 We shall, however, not be held liable for any loss or injury caused to You or any related/third party as a consequence of providing the
          data to the Application.<br></br>
          <br></br>3.4 Wherein the details of login are provided to You it shall be solely your responsibility to ensure the safe storage and non-disclosure of
          Your crucial login data.<br></br>
          <br></br>3.5 We shall ensure our policies and data protection mechanism are the most secure and reliable throughout. However, no transmission can be
          guaranteed to be privy of possibility of data-breach and hence, We can promise maximum security as per our capacity but not absolute security. We will
          not be liable for any damages of any kind arising from the use of the platform, including, but not limited to any indirect, incidental, special,
          consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly or any loss of data, use, good-will, or
          other intangible losses.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>4. PURPOSE OF DATA COLLECTION</b>
          <br></br>
          <br></br>
          4.1 The purpose for the same shall be as below but not limited to, as per Applicable Laws: <br></br>
          <br></br><p style={{padding:'0 20px'}}>a. Establish customer identity and verify the same;</p><p style={{padding:'0 20px'}}>b. Facilitate Your KYC as per instructions from our lending partners;</p><p style={{padding:'0 20px'}}>c.
          Troubleshoot problems, monitor, improve and administer our Application;</p><p style={{padding:'0 20px'}}>d. Provide our Service i.e., facilitating loans to You or providing
          our value-added Services or non-lending Services to You.</p><p style={{padding:'0 20px'}}>e. Diagnose service or technical problems and ensure security;</p><p style={{padding:'0 20px'}}>f. Send
          communications, notifications, information regarding the product/s or service/s requested by You or revert to queries that You may have of the
          Application;</p><p style={{padding:'0 20px'}}>g. Protect consumer interest and satisfaction in our products and Services.</p><p style={{padding:'0 20px'}}>h. Manage customer relations;</p><p style={{padding:'0 20px'}}>i.
          Marketing and promotional purposes including sending you promotional SMS, Email and WhatsApp and inform You about online and offline offers, products,
          services, and updates;</p><p style={{padding:'0 20px'}}>j. Conduct data analysis in order to improve the Services / Products provided to the User;</p><p style={{padding:'0 20px'}}>k. To ensure
          compliance with laws and regulations;</p><p style={{padding:'0 20px'}}>l. User information in other ways permitted by law to enable You to take financial services from our
          lending partners.</p><p style={{padding:'0 20px'}}>m. Resolve disputes and detect and protect Us against suspicious or illegal activity, fraud and other criminal activity;</p>
          <p style={{padding:'0 20px'}}>n. Customize your experience and enforce our terms and conditions.</p>
        </span>
        <br></br>
        <span>
          <b>5. INFORMATION COLLECTED FROM THIRD PARTIES</b>
          <br></br>
          <br></br> We shall collect information such as the number of days worked in a month, your name, address, other details from the employee management
          platform i.e. iConnect.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>6. DISCLOSURES</b> <br></br>
          
          <br></br>We will share the information only in such manner as described below:<br></br> <br></br> 
          <p style={{padding:'0 20px'}}> a. We may disclose and share Your information with the
          financial service providers, banks, our lending partners and other service providers for facilitation of a loan or facility or line of credit or any
          product/services;</p>
          <p style={{padding:'0 20px'}}> b. We share Your information with our third-party partners in order to conduct data analysis in order to serve You better
          and provide Services our Application;</p>
          <p style={{padding:'0 20px'}}>c. We may share Your information with another business entity should We (or our assets) merge with, or
          be acquired by that business entity, or re-organization, amalgamation, restructuring of business for continuity of business. Should such a transaction
          occur than any business entity (or the new combined entity) receiving any such information from Us shall be bound by this Policy with respect to Your
          information.</p>
          <p style={{padding:'0 20px'}}>d. We will disclose the information to our third-party technology or third-party data source providers, if any;</p>
          <p style={{padding:'0 20px'}}> e. We
          will share Your information under a confidentiality agreement with the third parties and restrict use of the said information by third parties only
          for the purposes detailed herein. We warrant that there will be no unauthorised disclosure of your information shared with third parties.</p>
          <p style={{padding:'0 20px'}}>f.
          We shall disclose Your data to the relevant regulatory authorities as a part of our statutory audit process.</p>
          
          <p style={{padding:'0 20px'}}> g. We shall disclose Your data
          (i) as required by the law or in order to comply with judicial proceedings, court orders or legal or regulatory proceedings; (ii) necessary to protect
          the safety of our employees, our property or the public and (iii) necessary for the prevention or detection of crime, including exchanging information
          with other companies or organizations for the purposes of fraud protection and credit risk reduction</p> 
          
          By accepting this Privacy Policy, You
          hereby provide Your consent to disclose Your Personal Data for disclosures. At all stages, We shall seek Your consent for any disclosures with any
          other third party.
        </span>
        <br></br> <br></br>
        <span>
          <b>7. DATA STORAGE AND DELETION</b> <br></br>
          <br></br>
          7.1 We promise to protect Your Personal Data from unauthorized access, misuse, and disclosure using the right security measures based on the type of
          data and how We are processing the same. We retain information about You to provide a seamless experience, to contact You in case of support required
          and about Your account, to detect, mitigate, prevent, and investigate fraudulent or illegal activities during the course of the Services.<br></br><br></br>7.2
          We shall store basic minimal data that may be required to carry out Our services that are requested by You through our Application in compliance with
          the Applicable Laws and RBI Guidelines in place. For accessing and usage of our Services, We may store information including but not limiting to:
          <br></br>
          <br></br>
          <p style={{padding:'0 20px'}}>
          1. Personal Data:<br></br> a. Name <br></br>b. Contact Number <br></br>c. Date of Birth <br></br>d. Gender <br></br>e. Email Id <br></br>f. Address (Temporary/Permanent)</p>
          <p style={{padding:'0 20px'}}> 2. Employment
          details like name of the company, employee id etc. and any other employee information as may be required for rendering services requested by You on
          the Application and as requested by the Bank/lenders/third parties providing the Services from time to time.</p>
          <p style={{padding:'0 20px'}}>3. In accordance with the RBI
          regulations, banks/lenders/third party service providers may ask for additional on-boarding/KYC documents via one-time access through camera,
          microphone, location etc. The consent for the same shall be acquired explicitly through You.</p>
          
          <p style={{padding:'0 20px'}}>4. Usage Data- We may collect data as to how you
          interact with the Application such as screen actions, dates and times of access, pages viewed etc.</p>
          <br></br>7.3 Subject to the RBI Guidelines, the data that is stored as mentioned in this Clause shall be preserved for a reasonable period of time.
          <br></br>
          <br></br>7.4 It is hereby declared that the data shall be stored while ensuring that highest security standards are being complied with while handling
          of such data and prevent any breaches, thereof; that may arise.<br></br>
          <br></br>7.5 Subject to this section, We may delete your data upon reasonable written request for the same at any stage. However, You may not be able
          to use our Services after deletion.
        </span>
        <br></br>
        <br></br>
        <span>
          <b> 8. THIRD PARTY COMPLIANCES</b>
          <br></br>
          <br></br> As per this Privacy Policy, we’re only addressing the disclosure and use of data collected by Us. The data collection practices of a third
          party which may be providing services to You under the aegis of our application, and their policies might be different from this Policy, and We do not
          have control over any of their policies neither do We have any liability in this regard.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>9. INFORMATION COLLECTED BY INSTAPE APP YOUR RIGHTS AGAINST IT</b>
          <br></br>
          <br></br>9.1 In an event You find that the data/information provided by You is inaccurate, incomplete and/or outdated, You shall have the right to
          inform Us of the same or make the changes on Your end immediately.<br></br>
          <br></br>9.2 Your Privacy Controls: You have certain choices regarding the information We collect and how it is used:<br></br><br></br>
          <p style={{padding:'0 20px'}}> a. Your device may have
          controls that determine what information We collect. For example, You can modify permissions on Your Android/iOS device or browser to remove any
          permissions that may have been given. However, the Application does not provide a guarantee of Services if any such controls are exercised.</p>
          <p style={{padding:'0 20px'}}> b.
          Delete the Application from Your device</p>
          <p style={{padding:'0 20px'}}>  c. You can also request to remove content from our servers in accordance with Clause </p>
          <br></br>9.3 Withdrawal/Denial of Consent: You acknowledge that the Application has duly collected the information with Your consent, and You have the
          option to not provide such information or deny consent for use of specific information or revoke the consent already given. However, any withdrawal of
          such personal information will not be permitted in case any Service availed by You is active. Where a consent has been withdrawn, the Application does
          not guarantee or be liable for providing such Service.<br></br>
          <br></br>However, We, shall not retain Your data and information if it is no longer required by Us and there is no legal requirement to retain the
          same. Do note that multiple legal bases may exist in parallel, and We may still have to retain certain data and information at any time. Also, the
          information may still be used for execution of any outstanding or termination activity of any lending or non-lending Services.<br></br>
          <br></br>We, Instape App, hereby reserve the right to change, modify, add, or remove portions of this Privacy Policy at any time for any reason. In
          case, any changes are made in the Privacy Policy, We shall update the same on the Application. We encourage You to periodically review this page for
          the latest information on our privacy practices. Your continued access or use of the Services constitute Your acceptance of the changes and the
          amended Privacy Policy.<br></br>
          <b>Issues and Grievances:</b> In case You wish to report an issue: You have a right to report a security incident to the Grievance Redressal Officer
          (GRO) having following credentials:<br></br>Name: Durairaj Ponnusamy<br></br> Email: nodalofficer@instape.com<br></br> Contact Number: +91 8655408338 <br></br>
          <br></br>We declare that:<br></br><br></br> 
          <p style={{padding:'0 20px'}}>&#x2022; We use encryption to keep your data private while in transit;</p>
          <p style={{padding:'0 20px'}}>&#x2022; We offer security feature
          like an OTP verification to help You protect your account;</p>
          <p style={{padding:'0 20px'}}>&#x2022; We review our information collection, storage, and processing practices,
          including physical security measures, to prevent unauthorized access to our systems;</p>
          <p style={{padding:'0 20px'}}>&#x2022; We restrict access to personal information to
          our employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual
          confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations;</p>
          <p style={{padding:'0 20px'}}>&#x2022; Compliance &amp; Cooperation
          with Regulations and applicable laws;</p> <p style={{padding:'0 20px'}}>&#x2022; We regularly review this Privacy Policy and make sure that We process your information in ways
          that comply with it.</p>
        </span>
        <br></br>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <span style={{ fontSize: "20px", fontWeight: 900 }}>Schedule I- Details of Third Party</span>
          <br></br>
          <br></br>
          <span>Following is the list of third parties, as on date, with whom Your data will be shared:</span>
        </div>
        <div className="scheduleIDetails">
          <table>
            <thead>
              <tr>
                <th>S. No</th> <th>Name of the Party</th> <th>Name of the Concerned Person</th> <th>Contact Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td> <td>Suryoday Small Finance Bank Limited</td> <td>-</td> <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Policy;
