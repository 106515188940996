import React from "react";
import "./css/Policy.css";
const Terms = () => {
  return (
    <>
      <div className="container mb-5">
        <h1 style={{ textAlign: "center" }}>TERMS OF USE</h1>
        <span style={{marginTop:20}}>
          Instape Synergies Private Limited (<b>“InstaPe”</b> or <b>“Company”</b>) is a company incorporated under the Companies Act, 2013. The Company has
          developed a InstaPe App that facilitates certain services, including but not limited to loan against accrued salary facility, to its customers
          (hereinafter referred to as “Instape App” or “Application”). For providing various services, InstaPe may enter into arrangement with third parties
          such as banks, NBFCs, corporates, etc. The Company commits to facilitate services through the Application that ensures an impermeable protection to
          the users of the Application (may alternatively be referred to as <b>“You”</b>, <b>“User”</b> or <b>“Employee"</b>).<br></br>
          <br></br> These Terms of Use (<b>“Terms”</b> or <b>“Terms of Use”</b>) shall be deemed to be an electronic record laying down the mutually acceptable
          terms for use of InstaPe App by You. Being an electronic record generated by or through a computer system, it does not require any physical or digital
          signatures.<br></br>
          <br></br> All transactions enabled on this InstaPe App are guided by the Terms mentioned herein. Please read these Terms carefully before using the
          InstaPe App for online transactions.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>1. DEFINITIONS AND INTERPRETATION</b> <br></br> <br></br>1.1. “Applicable Law” shall mean any statute, law, regulation, ordinance, rule, judgment,
          order, decree, by-law, approval from the concerned authority, government resolution, directive, guideline, policy, requirement, or other governmental
          restriction or any similar form of decision, or determination by, or any interpretation, or adjudication having the force of law or any of the
          foregoing. <br></br> <br></br>1.2. “Force Majeure Event” shall mean any event due to any cause beyond the reasonable control of a Party, including,
          without limitation, unavailability of any communication system, transmitters, sabotage, fire, vandals, flood, explosion, hacking, pandemic, lockdowns,
          acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, strike, embargo, war or acts of government; <br></br>{" "}
          <br></br>1.3. “Intellectual Property” means ideas, concepts, algorithms, software programs, patents, registered designs, trademarks and service marks,
          copyright, database right, source codes, design right and moral right, in each case existing or pending registration/ approval in any jurisdiction in
          the world, and other property rights in other jurisdictions that grant similar rights as the foregoing, including those subsisting in inventions,
          drawings, performances, software, databases, business names, goodwill and the style of presentation of goods and services and in applications for the
          protection thereof. <br></br> <br></br>1.4. “Privacy Policy” shall mean the privacy policy of InstaPe, as provided on the website and InstaPe App;{" "}
          <br></br> <br></br>1.5. The words &quot;we&quot;, &quot;us&quot; and &quot;our&quot; refer to InstaPe App/ Instape as the case may be and shall be
          construed accordingly; <br></br> <br></br>1.6. The words “you”, “your” and “user” shall mean the person approved to perform transaction(s) on the
          InstaPe App by Instape and shall be construed accordingly.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>2. ACCEPTANCE OF TERMS</b>
          <br></br>
          <br></br>2.1. Usage of InstaPe App is subject to the Terms governing InstaPe. Use of InstaPe App abides You to these Terms. In addition, when using
          the InstaPe App, You will be subject to all guidelines or rules applicable to InstaPe App that may be posted from time to time at the InstaPe website:
          www.instape.com<br></br>
          <br></br>2.2. Usage of InstaPe App shall be deemed to be the acceptance of these Terms.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>3. YOUR OBLIGATIONS</b>
          <br></br>
          <br></br>3.1. You shall provide complete, correct, honest and current information as required by Instape on the InstaPe App registration page
          including but not limited to the registration information (“User Information”). If You provide any User Information that is untrue, inaccurate, not
          current or incomplete, or if there are reasonable grounds to suspect that the User Information provided by You is untrue, inaccurate, not current or
          incomplete, Instape reserves the right to suspend, terminate, or refuse your current or future use of InstaPe App. To the extent that You have
          provided User Information to use the InstaPe App, You represent that You have all rights required to provide such User Information and further
          represent that it is true and accurate.<br></br>
          <br></br>3.2. All User Information provided by You will be used and processed in accordance with these Terms, Privacy Policy and the prevalent
          statutes. You acknowledge and agree that You have been provided with unrestricted access to the Privacy Policy and that it is your responsibility to
          read and understand the Privacy Policy. In the event You disagree with anything set forth in the Privacy Policy, it is your responsibility to bring it
          to Instape’s notice by contacting the Grievance Redressal Officer.
        </span>
        <br></br> <br></br>
        <span>
          <b>4. YOUR REPRESENTATIONS AND WARRANTIES</b>
          <br></br>
          <br></br>You explicitly agree, represent and warrant that You:<br></br>
          <br></br>4.1. can enter into legally binding contracts, are not &quot;incompetent to contract&quot; under the Indian Contract Act, 1872 and have the
          capacity to enter into the present contract.<br></br>
          <br></br>4.2. shall not give access to your respective account to others or transfer account to anyone else.<br></br>
          <br></br>4.3. or Your account has not previously been disabled by InstaPe for violations of its Terms or policies.<br></br>
          <br></br>4.4. are not prohibited from using the InstaPe App or the products and service under Applicable Laws or orders/directions/judgments of
          courts.<br></br>
          <br></br>4.5. shall not do anything that is unlawful, misleading, discriminatory or fraudulent.<br></br>
          <br></br>4.6. shall not upload viruses or malicious code, or do anything that could disable, overburden or impair the proper working or appearance of
          InstaPe App or interfere with or circumvent the security features of the InstaPe App or the products and services or any related mobile application,
          other mobile applications, or the internet.<br></br>
          <br></br>4.7. shall not access or collect data from InstaPe App using automated means or attempt to access data for which there is no permission to
          access.<br></br>
          <br></br>4.8. shall not breach these Terms or any other terms and policies that apply to use of InstaPe App. <br></br>
          <br></br>4.9. agree, undertake and covenant that, during the use of the InstaPe App, You shall not use, host, display, upload, modify, amend, publish,
          transmit, update or share any information or content including but not limited to User Information that:<br></br>
          <br></br>
          <p style={{padding:'0 20px'}}>
          4.9.1. belongs to another person or entity and in relation to which You do not have any right.</p>
          
          <p style={{padding:'0 20px'}}>
          4.9.2. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another
          person&#39;s privacy, hateful or racially or ethnically objectionable, or encouraging money laundering or gambling, or otherwise unlawful in any
          manner whatsoever.</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.3. is misleading or false in any way.</p> 
          
          <p style={{padding:'0 20px'}}>
            4.9.4. involves the transmission of ‘junk mail’, ‘chain letters’, or unsolicited mass mailing or ‘spamming’.</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.5. infringes upon or violates any third party&#39;s rights including, but not limited to, Intellectual Property rights, rights of privacy
          (including without limitation unauthorized disclosure of a person&#39;s name, e-mail address, physical address, phone number, photographs) or rights
          of publicity.</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.6. provides instructional information about illegal activities such as violating someone&#39;s privacy, or providing or creating computer
          viruses and malware such as spyware, worms, trojans, etc.</p>
          
          <p style={{padding:'0 20px'}}>
          4.9.7. tries to gain unauthorized access or exceeds the scope of authorized access to InstaPe App or to account information of other Users
          etc.</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.8. engages in commercial or business activities including but not limited to activities for gain, contests, sweepstakes, barter,
          advertising etc.</p>
          
          <p style={{padding:'0 20px'}}>
          4.9.9. interferes with another user&#39;s use of the InstaPe App. </p>
           
          <p style={{padding:'0 20px'}}>
          4.9.10. contains software viruses or any other computer codes, files or
          programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms or other computer
          programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data or personal
          information.</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.11. harms minors in any way;</p> 
          
          <p style={{padding:'0 20px'}}>
          4.9.12. threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation.</p>
           <p style={{padding:'0 20px'}}>4.9.13. is a misuse or unauthorized use of the InstaPe App or is a use prohibited by law or is unlawful.</p>
          <br></br>
          4.10. acknowledge that InstaPe App is only a facilitator of services and does not have any obligation or responsibility to pay, repay or disburse any
          amounts in relation to the services facilitated on the InstaPe App including but not limited the loan against accrued salary facility. You agree that
          facility provided by the InstaPe App pertaining to the loan against accrued salary facility shall be subject to the certain criteria and
          qualifications which inter-alia includes Your accrued salary as may be informed by Your employer, eligibility criteria set out by Your employer, KYC
          and regulatory checks by the bank, NBFC, lender. InstaPe shall not be held responsible for any criteria, disqualification, rejection of Your
          application and repayment of the loan availed against accrued salary facility, interest and other processing charges, if any.
        </span>
        <br></br>
        <br></br>
        <span>
          <b>5. AUTHENTICATION </b><br></br>
        <br></br>5.1. During registration on InstaPe App, You may be required to select an authentication method viz. OTP (One Time Password) or
          password for authenticating yourself. Along with that, You may be required to set a PIN for using the InstaPe App. If You are unable to enter the
          correct PIN on opening the InstaPe App or if the authentication otherwise fails, the registration will fail. Please note that You will be required to
          use the PIN for transactions done via InstaPe App.<br></br><br></br> 5.2. You are successfully registered on the InstaPe App only after You receive confirmation of the
          same from Instape by way of in-InstaPe App notification/communication.
          <br></br>
          <br></br><b>6. CONFIDENTIALITY AND SECURITY OF REGISTRATION INFORMATION</b><br></br>
        <br></br> 6.1. You are liable entirely for maintaining the confidentiality of the User
          Information viz. PIN and other verification information used on the InstaPe App. All activities that occur using the User Information or other
          verification information supplied to or established by You with respect to InstaPe App will be Your sole responsibility. Instape is not liable if the
          User Information is misused by You due to any reason whatsoever.<br></br><br></br> 6.2. You are responsible not to share information which enables access/ usage of
          InstaPe App to any third party. You should immediately notify Instape of any unauthorized use of the InstaPe App, verification information, or any
          other breach of security. You agree that Instape will not be liable for any loss or damage arising from Your failure to comply with these Terms.{" "}
          <br></br>
          <br></br><b>7. CONFIDENTIALITY OF USER INFORMATION</b><br></br><br></br> 7.1. The User Information will not be shared with any third party, except wherein such information is
          required to be shared for the availment of services from such respective third party. <br></br><br></br>7.2. You agree to permit Instape to store the User Information
          in their databases and are permitted to disclose it if required to do so by Applicable Law, in good faith believing that such preservation or
          disclosure is permitted by Applicable Law, or as reasonably necessary to (i) comply with legal process or (ii) enforce these Terms. <br></br><br></br>7.3. You hereby
          grant Instape the right to use the User Information for the furtherance of these Terms and to store it and use it in a manner (either singularly or as
          an aggregate with information provided by other users on the InstaPe App) that improves features and functionalities of the InstaPe App or for
          undertaking any other analytics in relation to such information. <br></br><br></br>7.4. InstaPe has put in necessary security protocols and encryption to ensure that
          any User Information is not breached or misused. 7.5. InstaPe does not store any information except as may be required to provide services. Any
          documents/ information required by third party service providers (banks, NBFCs, corporates, etc) such as KYC documents provided by You such as Aadhar
          and PAN are forwarded to the respective third party service provider and InstaPe does not store the same. <br></br>
          <br></br><b>8. MERCHANTS </b> <br></br> <br></br>8.1. InstaPe App merely offers a mode to You to do online digital transactions with the merchants. Instape, by no means intends
          to endorse any merchant over others. Instape does not validate the merchant’s services or offering. Additionally, Instape does not guarantee You any
          experience with the merchant in terms of delivery of product, quality etc. Your interaction with the merchant is independent of governance of InstaPe
          App rules.  <br></br> <br></br>8.2. Merchant’s terms of business with You, with regards to service/ product quality, delivery, payment, guarantees / warranties,
          promotions, discounts etc. is an understanding between the You and the merchant alone even if the You used InstaPe App for making the transaction. In
          no event will You be liable for any loss or damage arising from the merchant’s service or product, including without limitation, indirect or
          consequential loss or damage, purchased using the InstaPe App. <br></br>
          <br></br><b>9. INTERNET FRAUDS </b> <br></br> <br></br>The internet per se is susceptible to a number of frauds, misuses, hacking and other actions which could affect the use of
          InstaPe App. Whilst Instape shall actively strive to provide security to prevent the same, there cannot be any absolute guarantee from such internet
          frauds, hacking and other actions which could affect the use of the InstaPe App. Instape will not take liability for any viruses, malware or unlawful
          downloads that Your system may be exposed to while You accesses the internet for using InstaPe App. You shall separately evaluate all risks arising
          out of the same. <br></br>
          <br></br><b>10. OPERATIONAL ISSUES</b>  <br></br> <br></br>Every effort is made to keep the InstaPe App/ website up and running smoothly. However, Instape will not be held
          liable for the website/InstaPe App being temporarily unavailable due to operational issues beyond control of Instape. <br></br>
          <br></br><b>11. INDEMNITY AND LIMITATION OF LIABILITY</b>  <br></br> <br></br>You agree to indemnify and hold harmless Instape, its directors, shareholders, officers, employees
          and agents, against any claims, losses, damages or costs arising from (i) your use of the InstaPe App and its contents, (ii) your breach of these
          Terms or (iii) any acts or omission of yours in relation to the InstaPe App and its use thereof. The aggregate liability of the Instape to You or
          anyone, whether in contract, tort, negligence or otherwise, howsoever arising, whether in connection with these Terms, your access and use of the
          InstaPe App and its contents and functionalities or for any reason related to the operation of the InstaPe App, shall not exceed INR 5,000. In no
          event shall the Instape be liable for failed transactions which are incomplete due to any reason and any loss of profits (anticipated or real), loss
          of business, loss of reputation, loss of data, loss of goodwill, any business interruption or any direct, indirect, special, incidental,
          consequential, punitive, tort or other damages, however caused, whether or not it has been advised of the possibility of such damages. <br></br>
          <br></br><b>12. DISCONTINUATION OF INSTAPE APP</b><br></br><br></br> Instape reserves the right to discontinue the above service at any time whatsoever without prior
          intimation to You. <br></br>
          <br></br><b>13. GRIEVANCE REDRESSAL </b><br></br><br></br>If You have any complaints, grievances or questions related to the InstaPe App and other content and services, please
          contact our Grievance Redressal Officer in the following manner:<br></br><br></br> Name: Durairaj Ponnusamy<br></br> E-mail:  nodalofficer@instape.com<br></br> Contact Number: +91 8655408338 <br></br>
          <br></br><b>14. MISCELLANEOUS </b><br></br><br></br>14.1. Amendments: InstaPe reserves the right to modify this Terms or policies including but not limited to Privacy Policy
          relating to the InstaPe App at any time, effective upon posting of an updated version of this Terms or the said policies, as the case may be, on the
          InstaPe App. Upon doing so, the revised updated date shall be reflected at the bottom of the page containing the Terms or the said policies. Continued
          use of the InstaPe App after any such changes shall constitute your consent to such changes to the Terms or said policies. <br></br><br></br>14.2. Force Majeure: In no
          event shall Instape be held liable for any failure to perform any of its obligations, if the performance is prevented, hindered or delayed by a Force
          Majeure Event. <br></br><br></br>14.3. Waiver: Any failure on the part of Instape to require performance of any provision of these Terms shall not affect its right to
          full performance thereof at any time thereafter, and any waiver by Instape of a breach of any provision hereof shall not constitute a waiver of a
          similar breach in the future or of any other breach. <br></br><br></br>14.4. Governing Law: These Terms are governed by the laws of India. You irrevocably consent to
          the exclusive jurisdiction and venue of the competent courts located at Mumbai, Maharashtra, India for all disputes arising out of or relating to
          these Terms. <br></br><br></br>14.5. Arbitration: All disputes, differences or claims arising out of or in connection with these Terms, shall be referred upon to a sole
          arbitrator (to be mutually agreed between the parties) and finally settled in accordance with the rules of the Arbitration and Conciliation Act, 1996.
          The seat and venue of arbitration shall be Mumbai and shall be conducted in English language.
        </span>
      </div>
    </>
  );
};

export default Terms;
