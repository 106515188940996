import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import logo from "../components/assets/logo.svg";
import { useNavigate } from "react-router-dom";
import "./TopBar.css";

const TopBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleContactUsClick = () => {
    //window.location.href = "mailto:signup@instape.com";
    navigate("/connect");
  };

  const handleHomeClick = () => {
    navigate("/");
  };
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.body.style.backgroundColor = "#FFFFFF !important";
      document.body.style.color = "#000000";
    }
  }, []);
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          background: "#ffffff",
        }}
        media="(prefers-color-scheme: light)"
      >
        <div className="container">
          <Navbar
            expand="lg"
            className="mb-3 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <Navbar.Brand
              style={{ cursor: "pointer" }}
              onClick={handleHomeClick}
            >
              <img
                src={logo}
                className="img-fluid"
                alt="Cinque Terre"
                height="30"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              className="mobileHide"
              aria-controls="basic-navbar-nav"
              onClick={handleDrawerToggle}
            />
            <Navbar.Collapse
              className="mobileHide"
              style={{ justifyContent: "flex-end" }}
              id="basic-navbar-nav"
            >
              <Nav className="mr-auto"></Nav>
              <Button
                style={{ backgroundColor: "#216FB8" }}
                onClick={handleContactUsClick}
              >
                Contact Us
              </Button>
            </Navbar.Collapse>
            <Button
              className="tabletTop"
              style={{ backgroundColor: "#216FB8" }}
              onClick={handleContactUsClick}
            >
              Contact Us
            </Button>
          </Navbar>
        </div>
      </div>
    </>
  );
};
<style></style>;
export default TopBar;
